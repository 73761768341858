var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unsaved-message" }, [
    _c("p", [
      _vm._v(
        'You have unsaved changes. Please "Save" to continue or alternatively cancel to review all edits.'
      ),
    ]),
    _c("div", { staticClass: "btn-wrapper flex flex--row-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn--discard cancel",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("cancelMove")
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn--default",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("saveChanges")
            },
          },
        },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }